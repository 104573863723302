@font-face {
  font-family: 'kozGoPro-regular';
  src: url(../fonts/KozGoPro-Regular[3].otf);
}
@font-face {
  font-family: 'kozGoPro-bold';
  src: url(../fonts/KozGoPro-Bold[2].otf);
}
.banner {
    display: inline-block;
    color: #fff;
    padding: 2rem 1rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    letter-spacing: 3px;
  }
  .banner h1 {
    font-family: kozGoPro-bold;
    font-size: 2.5rem;
    color: #fff;
  }
  .banner div {
    width: 10rem;
    height: 5px;
    background: #fff;
    margin: 1.7rem auto;
  }
  .banner p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 576px) {
    .banner {
      padding: 2rem 3rem;
    }
    .banner h1 {
      font-size: 3rem;
    }
  }
  @media screen and (min-width: 992px) {
    .banner {
      padding: 2rem 6rem;
    }
    .banner h1 {
      font-size: 3rem !important;
    }
  }

  @media screen and (max-width: 576px) {
    .banner h1 {
      font-size: 2rem;
    }
  }