@media screen and (min-width: 993px) {
  .formBody > div > div > div > div > div > div > a {
    font-family: Roboto!important;
    font-size: clamp(9px,1.6vw,14px)!important;
    text-transform: capitalize!important;
    white-space: normal;
    border: 1px solid;
  }
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  .formBody > div > div > div > div > div > div > h4 {
    font-size: 21px;
  }
  .formBody > div > div > div > div > div > div > a {
    font-size: 9px !important;
  }
}

@media screen and (max-width: 575px) {
  .formBody > div > div > div > div > div > div > h4 {
    font-size: clamp(13px,3.7vw,21px) !important;
  }
  .formBody > div > div > div > div > div > div > a {
    font-family: Roboto!important;
    font-size: clamp(9px,3.2vw,14px)!important;
    text-transform: capitalize!important;
    white-space: normal;
    border: 1px solid;
  }
}