.bottomLinks a {
    color: #222222;
    padding: 5px; 
}
.bottomLinks a:hover {
    color: #15549a; 
}
@media screen and (max-width: 768px) {
    .sub-footer-row{
        margin-right: 0;
        margin-left: 0;
    }
}